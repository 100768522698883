export default class Content {
    constructor(value, config) {
        this.value = value;
        this.config = config;
    }

    get() {
        return this.value;
    }

    length() {
        return this.value.length;
    }

    trim() {
        this.value = this.value.replace(/  +/g, " ");

        return this;
    }

    map() {
        Object.keys(this.config.map).forEach((key) => {
            this.value = this.value.replaceAll(key, this.config.map[key]);
        });

        return this;
    }

    lower() {
        this.value = this.value.toLowerCase();

        return this;
    }

    filterUnsupported() {
        this.value = this.value
            .split("")
            .filter((char) => this.config.support.includes(char))
            .join("");

        return this;
    }
}
