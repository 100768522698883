import { tap } from "@tobiasthaden/tap";

export default class Consent {
    constructor(modules) {
        this.modules = modules || {};

        this.fetchSettings();
    }

    isExpired() {
        if (!this.settings.hasOwnProperty("expires")) {
            return true;
        }

        return parseInt(this.settings.expires) <= (new Date()).getTime();
    }

    fetchSettings() {
        let settings = window.localStorage.getItem("_jar");
        this.settings = settings ? JSON.parse(settings) : {};
    }

    update(modules) {
        let now = new Date();

        // expires in a year
        let expires = now.setDate(now.getDate() + 365);

        this.settings.expires = expires;

        window.localStorage.setItem(
            "_jar",
            JSON.stringify({ modules, expires })
        );

        this.handle(modules);
    }

    handle(keys) {
        keys = keys ?? [];
        let modules = Object.keys(this.modules);

        modules
            .filter((item) => {
                return keys.includes(item)
            })
            .map((key) => tap(this.modules[key], (module) => module.enable()));

        modules
            .filter((item) => !keys.includes(item))
            .map((key) => tap(this.modules[key], (module) => module.disable()));
    }
}
