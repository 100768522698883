<template>
    <layout>
        <template v-slot:card>
            <div class="relative overflow-x-auto">
                <Editable
                    v-model="text"
                    size="7vw"
                    :edit="edit"
                    @resize="updateDimensions"
                />
                <Alphabet v-model="text" size="7vw" :dimensions="dimensions" />
            </div>
            <Counter :text="text" />
        </template>
        <template v-slot:toolbar>
            <Create @click="create" :class="{ 'opacity-25': edit }" />
            <Share :content="text" />
            <Download />
        </template>

        <template v-slot:cookie>
            <Consent />
        </template>
    </layout>
</template>

<script>
import Url from "./models/Url";
import Alphabet from "./components/Alphabet";
import Consent from "./components/Consent";
import Counter from "./components/Counter";
import Download from "./components/Download";
import Editable from "./components/Editable";
import Create from "./components/Create";
import Share from "./components/Share";

export default {
    data() {
        return {
            edit: false,
            text: "frohes\nfest!",
            dimensions: {
                width: "auto",
                height: "auto",
            },
        };
    },

    mounted() {
        if (this.$route.params.text) {
            this.text = Url.decode(this.$route.params.text);
        }
    },

    components: {
        Alphabet,
        Consent,
        Counter,
        Download,
        Editable,
        Create,
        Share,
    },

    methods: {
        create() {
            this.edit = true;
        },
        updateDimensions(dimensions) {
            this.dimensions = dimensions;
        },
    },
};
</script>
