const THEME = "swf";

import Vue from "vue";
import VueRouter from "vue-router";
import VuePortal from "portal-vue";
Vue.use(VueRouter);
Vue.use(VuePortal);

import GoogleAnalytics from "./services/GoogleAnalytics"
import CookieConsent from "./services/CookieConsent"
window.consent = new CookieConsent({
    ga: new GoogleAnalytics(document.head.querySelector('meta[name="ga-id"]').getAttribute("content")),
});

import App from "./App.vue";
import Config from "./config";
import "./assets/tailwind.css";

Vue.component("layout", Config[THEME]["layout"]);

new Vue({
    el: "#app",
    data: {
        config: Config[THEME],
    },
    router: new VueRouter({
        routes: [
            {
                path: "/:text?",
                name: "app",
                component: App,
            },
        ],
    }),
    render: (h) => h("router-view"),
});
