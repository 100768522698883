export default {
    a: "/assets/swf/a.png",
    b: "/assets/swf/b.png",
    c: "/assets/swf/c.png",
    d: "/assets/swf/d.png",
    e: "/assets/swf/e.png",
    f: "/assets/swf/f.png",
    g: "/assets/swf/g.png",
    h: "/assets/swf/h.png",
    i: "/assets/swf/i.png",
    j: "/assets/swf/j.png",
    k: "/assets/swf/k.png",
    l: "/assets/swf/l.png",
    m: "/assets/swf/m.png",
    n: "/assets/swf/n.png",
    o: "/assets/swf/o.png",
    p: "/assets/swf/p.png",
    q: "/assets/swf/q.png",
    r: "/assets/swf/r.png",
    s: "/assets/swf/s.png",
    t: "/assets/swf/t.png",
    u: "/assets/swf/u.png",
    v: "/assets/swf/v.png",
    w: "/assets/swf/w.png",
    x: "/assets/swf/x.png",
    y: "/assets/swf/y.png",
    z: "/assets/swf/z.png",
    ä: "/assets/swf/ae.png",
    ö: "/assets/swf/oe.png",
    ü: "/assets/swf/ue.png",
    "&": "/assets/swf/and.png",
    "!": "/assets/swf/exclamation.png",
    "?": "/assets/swf/question.png",
    ".": "/assets/swf/dot.png",
    ",": "/assets/swf/comma.png",
    "#": "/assets/swf/minus.png",
    "'": "/assets/swf/apostroph.png",
    " ": "/assets/swf/space.png",
};
