<template>
    <div
        class="p-8 lg:p-12 absolute top-0 pointer-events-none"
        :style="dimensions"
        style="border-color: #c3a468; border-width: 6px"
    >
        <div v-for="(line, lc) in content" :key="key(lc)" :style="style.line">
            <img
                v-for="(char, cc) in line"
                :key="key(lc, cc)"
                :src="$root.config.images[char]"
                :style="style.char"
            />
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$root.preview = this.$el;
    },

    props: ["value", "size", "dimensions"],

    computed: {
        content() {
            return this.value.split("\n");
        },
        style() {
            return {
                line: {
                    display: "flex",
                    height: this.size,
                    margin: "0 -0.5vw",
                    flex: "none",
                },
                word: {
                    display: "flex",
                    flex: "none",
                    padding: "0 0.5vw",
                },
                char: {
                    display: "block",
                    height: this.size,
                    flex: "none",
                },
            };
        },
    },

    methods: {
        key(line, word, char) {
            let key = `line-${line}`;

            if (word) {
                key = `${key}-word-${word}`;
            }

            if (char) {
                key = `${key}-word-${word}-char-${char}`;
            }

            return key;
        },
    },
};
</script>
