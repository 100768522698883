<template>
    <div class="w-1/6 lg:w-full">
        <button
            @click="download"
            class="flex justify-center md:justify-between items-center border-2 broder-white rounded-full px-2 w-full h-full"
        >
            <span class="hidden p-2 md:block"> Karte downloaden </span>
            <span class="h-8 px-2 py-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 13 18"
                    class="h-full"
                >
                    <path
                        fill="currentColor"
                        fill-rule="nonzero"
                        d="M7.33185841 17.0615372l5.02654867-6.72443252c.10619469-.14461145.14159292-.2892229.14159292-.43383435 0-.36152863-.28318584-.72305726-.7079646-.72305726H8.96017699V1.58388826c0-.83800982 0-1.05802091-2.46017699-1.08227509C4.039823.47735898 4.0044248.72145481 4.0044248 1.58388826v7.63247767H1.2079646C.7831858 9.21636593.5 9.57789456.5 9.93942319c0 .14461145.0353982.2892229.1415929.43383436L5.66814159 17.09769c.38938053.542293 1.23893806.542293 1.66371682-.0361528z"
                    />
                </svg>
            </span>
        </button>
        <a style="display: none" ref="link" :href="image" download="xmas-card">
            Download
        </a>
    </div>
</template>

<script>
import render from "html2canvas";

export default {
    data() {
        return {
            image: null,
        };
    },

    methods: {
        async download() {
            await this.updatePosition();
            await this.makeImage();

            this.$refs.link.click();
        },

        async updatePosition() {
            return new Promise((resolve, reject) => {
                window.scrollTo(0, 0);
                resolve();
            });
        },

        async makeImage() {
            return new Promise((resolve, reject) => {
                render(this.$root.preview).then((canvas) => {
                    canvas.toBlob((blob) => {
                        console.log();
                        this.image = URL.createObjectURL(blob);
                        resolve();
                    });
                }, "image/png");
            });
        },
    },
};
</script>
