<template>
    <div class="w-1/6 lg:w-full">
        <button
            @click="share"
            class="flex justify-center md:justify-between items-center border-2 broder-white rounded-full px-2 w-full h-full"
        >
            <span class="hidden p-2 md:block"> Karte teilen </span>
            <span class="h-8 p-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 13"
                    class="h-full"
                >
                    <path
                        fill="currentColor"
                        fill-rule="nonzero"
                        d="M17.5637097 5.40740741L10.8725953.14814815C10.7287003.03703704 10.5848054 0 10.4409105 0c-.3597374 0-.71947469.2962963-.71947469.74074074V3.7037037H7.92274914c-5.1802176 0-7.3386416 3.33333334-7.91422133 8.4814815C-.06341965 12.6666667.33229141 13 .72800248 13c.2158424 0 .4316848-.1111111.61155347-.3333333 1.72673919-2.5185186 3.59737333-3.77777781 6.94293053-3.77777781h1.47492306v2.92592591c0 .4444445.35973736.7407408.71947466.7407408.1438949 0 .2877899-.0370371.4316848-.1481482l6.6911144-5.25925925c.539606-.40740741.539606-1.2962963-.0359737-1.74074074z"
                    />
                </svg>
            </span>
        </button>
        <portal to="dialog">
            <CopyUrl v-if="showDialog" />
        </portal>
    </div>
</template>

<script>
import Url from "@/models/Url";
import CopyUrl from "@/components/CopyUrl";

export default {
    data() {
        return {
            showDialog: false,
        };
    },

    props: ["content"],

    components: {
        CopyUrl,
    },

    computed: {
        routeParams() {
            return {
                text: Url.encode(this.content),
            };
        },

        shareData() {
            this.$root.config.shareData.url = this.url;
            return this.$root.config.shareData;
        },

        url() {
            return window.location;
        },

        style() {
            return {
                display: "flex",
                width: "120px",
            };
        },
    },

    methods: {
        async share() {
            await this.updateRoute();

            if (navigator.share) {
                return this.shareNative();
            }

            return this.openDialog();
        },

        async shareNative() {
            return navigator.share(this.shareData);
        },

        async updateRoute() {
            return new Promise((resolve, reject) => {
                this.$router
                    .push({
                        name: "app",
                        params: this.routeParams,
                    })

                    .catch(resolve)

                    .then(resolve);
            });
        },

        openDialog() {
            this.showDialog = true;
        },
    },
};
</script>
