<template>
    <textarea
        :style="style"
        @input="handleInput"
        :value="value"
        class="block p-8 lg:p-12"
    ></textarea>
</template>

<script>
import Content from "@/models/Content";

export default {
    props: ["value", "size", "edit"],
    data() {
        return {
            height: "auto",
            width: "100%",
        };
    },
    mounted() {
        this.$nextTick(() => this.resize(this.$el));

        let timeout;

        window.addEventListener("resize", () => {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(() => this.resize(this.$el), 500);
        });
    },
    methods: {
        handleInput(event) {
            let content = new Content(event.target.value, this.$root.config);

            content.lower().map().filterUnsupported();

            this.resize(event.target);

            if (
                this.$root.config.maxLength &&
                content.length() > this.$root.config.maxLength
            ) {
                return this.$emit("input", this.value);
            }

            return this.$emit("input", content.get());
        },

        resize(element) {
            this.width = "100%";
            this.height = "auto";
            this.$nextTick(() => {
                this.width = element.scrollWidth + "px";
                this.height = element.scrollHeight + "px";

                this.$emit("resize", {
                    width: this.width,
                    height: this.height,
                });
            });

            return this;
        },
    },
    computed: {
        style() {
            return {
                outline: 0,
                border: 0,
                color: "transparent",
                caretColor: "#C3A468",
                lineHeight: 1,
                letterSpacing: ".022em",
                textTransform: "uppercase",
                fontSize: this.size,
                height: this.height,
                width: this.width,
                minHeight: "52vh",
                whiteSpace: "pre",
                wordWrap: "normal",
                resize: "none",
            };
        },
    },

    watch: {
        edit(value) {
            if (value) {
                this.$emit("input", "");
                this.$nextTick(() => this.resize(this.$el).$el.focus());
            }
        },
    },
};
</script>
