import SwfSupport from "./swf/support";
import SwfMap from "./swf/map";
import SwfImages from "./swf/images";
import SwfShareData from "./swf/share";
import SwfLayout from "./swf/Layout";
import SwfLinks from "./swf/links";

export const swf = {
    maxLength: false,
    support: SwfSupport,
    map: SwfMap,
    images: SwfImages,
    shareData: SwfShareData,
    layout: SwfLayout,
    links: SwfLinks,
};

export default { swf };
