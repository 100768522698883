<template>
    <div v-if="show" class="sm:m-10 w-full sm:w-96 fixed z-50 bottom-0 left-0 shadow">
        <div class="bg-white p-5 sm:rounded">
            Diese Webseite verwendet Cookies, um bestimmte Funktionen zu ermöglichen und unser Online-Angebot zu verbessern.
            <div class="flex justify-between space-x-5 mt-5">
                <button @click="disable" style="color: #C3A468">Nicht erlauben</button>
                <button @click="enable" class="py-3 px-5 text-white rounded-full" style="background: #C3A468">Erlauben</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    mounted() {
        this.show = window.consent.isExpired();
    },
    methods: {
        enable() {
            window.consent.update(['ga']);
            this.show = consent.isExpired();
        },
        disable() {
            window.consent.update();
            this.show = consent.isExpired();
        },
    }
}
</script>
