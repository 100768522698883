export default class {
    static encode(attr) {
        return attr
            .replaceAll(" ", "+")
            .replaceAll("\n", "_")
            .replaceAll("#", "-");
    }

    static decode(attr) {
        return attr
            .replaceAll("+", " ")
            .replaceAll("_", "\n")
            .replaceAll("-", "#");
    }
}
