<template>
    <div
        v-if="maxLength"
        class="text-sm absolute bottom-0 right-0 p-4"
        style="color: #c3a468"
    >
        noch {{ charsLeft }} Zeichen
    </div>
</template>

<script>
export default {
    props: ["text"],
    computed: {
        maxLength() {
            return this.$root.config.maxLength;
        },
        charsLeft() {
            if (!this.maxLength) {
                return -1;
            }

            return this.maxLength - this.text.length;
        },
    },
};
</script>
