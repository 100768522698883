<template>
    <div>
        <div class="flex rounded-full border border-gray-500 overflow-hidden">
            <input class="flex-1 px-2 py-2" type="text" @click="copy" ref="copybag" :value="url" />
            <button class="px-3 py-2 border-l border-gray-500" @click="copy">Link kopieren</button>
        </div>
        <div class="absolute pt-2 text-xs" v-show="showMessage">In die Zwischenablage kopiert.</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showMessage: false,
        };
    },
    computed: {
        url() {
            return window.location;
        },
    },
    methods: {
        copy() {
            this.$refs.copybag.select();
            document.execCommand("copy");
            this.showMessage = true;
        },
    },
};
</script>
